"use client";

import { useThemeStorage } from "@/lib/theme/usetheme";
import { SunIcon, MoonIcon } from "@heroicons/react/24/outline";

export default function DarkThemeButton() {
  const { theme, toggleTheme } = useThemeStorage();
  return (
    <div className="btn-ghost btn" onClick={toggleTheme}>
      {theme == "dark" ? (
        <SunIcon className="h-8 w-8" />
      ) : (
        <MoonIcon className="h-8 w-8" />
      )}
    </div>
  );
}
