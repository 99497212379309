"use client";
import bg from "../../../../../public/images/bg.png";
import bgLight from "../../../../../public/images/bg-light.png";
import Link from "next/link";
import useTheme from "@/lib/theme/usetheme";

export default function Hero() {
  const theme = useTheme();
  return (
    <div
      className="hero min-h-screen"
      style={{
        backgroundImage:
          theme === "dark" ? `url(${bg.src})` : `url(${bgLight.src})`,
      }}
    >
      <div className="hero-overlay h-full w-full backdrop-blur-sm"></div>
      <div className="hero-content text-center text-neutral-content">
        <div className="max-w-md">
          <h1 className="mb-5 text-5xl font-bold">
            <span className="block">Text-Gen</span>
            <span className="block text-primary">Playground</span>
          </h1>
          <p className="mb-5">Explore the Boundless Possibilities</p>
          <Link
            className="btn btn-primary"
            href="obsidian://show-plugin?id=obsidian-textgenerator-plugin"
          >
            Get Plugin
          </Link>
        </div>
      </div>
    </div>
  );
}
